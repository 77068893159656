<template>
  <div class="main">
    <Top />
    <div class="content">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
    </div>
    <Foot />
  </div>
</template>

<script>
import Top from '@/components/Top'
import Foot from '@/components/Foot'

export default {
  components: {
    Top,Foot
  },
  computed: {

  },
  created() {
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>

</style>
