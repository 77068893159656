import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'

import 'lib-flexible'
import './assets/less/style.less'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
import './assets/theme/qj.css'

import request from './utils/request'
import { baseImageUrl } from '@/utils/common'

Vue.prototype.$http = request
// 全局样式
Vue.prototype.$globalcolor = 'yellow'
Vue.prototype.$globalcomplex = false
// 全局样式

import clipboard from 'clipboard'

Vue.prototype.clipboard = clipboard

Vue.prototype.$baseImageUrl = baseImageUrl
Vue.config.productionTip = false

// import Cookies from "js-cookie";
// if(Cookies.get('TOKEN')){
//     store.dispatch('updataBaseInfo')
// }

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')
