var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"p-center"},[_c('div',{staticClass:"header",class:this.$globalcomplex && this.$globalcolor == 'red'
					? 'header redpdfza'
					: this.$globalcomplex && this.$globalcolor == 'blue'
					? 'header bluepdfza'
					: this.$globalcomplex && this.$globalcolor == 'yellow'
					? 'header yellowpdfza'
					: 'header'},[_c('div',{staticClass:"logoBox",on:{"click":_vm.tzshouy}},[_c('img',{attrs:{"src":_vm.$baseImageUrl + _vm.foot.logo,"alt":""}}),_c('div',{staticStyle:{"margin-left":"10px","font-size":"36px","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.foot.webName)+" ")])]),_c('div',{class:this.$globalcomplex && this.$globalcolor == 'red'
						? 'hrefBox redpdfza'
						: this.$globalcomplex && this.$globalcolor == 'blue'
						? 'hrefBox bluepdfza'
						: this.$globalcomplex &&
						  this.$globalcolor == 'yellow'
						? 'hrefBox yellowpdfza'
						: 'hrefBox'},[_c('div',{class:this.$globalcomplex
							? (_vm.pdxsgl == 1 || _vm.pdxsgles == 1) &&
							  this.$globalcolor == 'red'
								? 'hrefTXt redgl redcss'
								: (_vm.pdxsgl == 1 || _vm.pdxsgles == 1) &&
								  this.$globalcolor == 'yellow'
								? 'hrefTXt yellowgl yellowcss'
								: (_vm.pdxsgl == 1 || _vm.pdxsgles == 1) &&
								  this.$globalcolor == 'blue'
								? 'hrefTXt bluegl bluecss'
								: 'hrefTXt'
							: (_vm.pdxsgl == 1 || _vm.pdxsgles == 1) &&
							  this.$globalcolor == 'red'
							? 'hrefTXt  redcss'
							: (_vm.pdxsgl == 1 || _vm.pdxsgles == 1) &&
							  this.$globalcolor == 'yellow'
							? 'hrefTXt  yellowcss'
							: (_vm.pdxsgl == 1 || _vm.pdxsgles == 1) &&
							  this.$globalcolor == 'blue'
							? 'hrefTXt  bluecss'
							: 'hrefTXt',on:{"click":function($event){return _vm.gotoHref('/', 1)},"mouseenter":function($event){return _vm.handleMouseEnter(1)},"mouseleave":_vm.handleMouseLeave}},[_vm._v(" 首页 ")]),_c('div',{class:this.$globalcomplex
							? (_vm.pdxsgl == 5 || _vm.pdxsgles == 5) &&
							  this.$globalcolor == 'red'
								? 'hrefTXt redgl redcss'
								: (_vm.pdxsgl == 5 || _vm.pdxsgles == 5) &&
								  this.$globalcolor == 'yellow'
								? 'hrefTXt yellowgl yellowcss'
								: (_vm.pdxsgl == 5 || _vm.pdxsgles == 5) &&
								  this.$globalcolor == 'blue'
								? 'hrefTXt bluegl bluecss'
								: 'hrefTXt'
							: (_vm.pdxsgl == 5 || _vm.pdxsgles == 5) &&
							  this.$globalcolor == 'red'
							? 'hrefTXt  redcss'
							: (_vm.pdxsgl == 5 || _vm.pdxsgles == 5) &&
							  this.$globalcolor == 'yellow'
							? 'hrefTXt  yellowcss'
							: (_vm.pdxsgl == 5 || _vm.pdxsgles == 5) &&
							  this.$globalcolor == 'blue'
							? 'hrefTXt  bluecss'
							: 'hrefTXt',on:{"click":_vm.toLogin,"mouseenter":function($event){return _vm.handleMouseEnter(5)},"mouseleave":_vm.handleMouseLeave}},[_vm._v(" 用户登录 ")]),_c('div',{class:this.$globalcomplex
							? (_vm.pdxsgl == 7 || _vm.pdxsgles == 7) &&
							  this.$globalcolor == 'red'
								? 'hrefTXt redgl redcss'
								: (_vm.pdxsgl == 7 || _vm.pdxsgles == 7) &&
								  this.$globalcolor == 'yellow'
								? 'hrefTXt yellowgl yellowcss'
								: (_vm.pdxsgl == 7 || _vm.pdxsgles == 7) &&
								  this.$globalcolor == 'blue'
								? 'hrefTXt bluegl bluecss'
								: 'hrefTXt'
							: (_vm.pdxsgl == 7 || _vm.pdxsgles == 7) &&
							  this.$globalcolor == 'red'
							? 'hrefTXt  redcss'
							: (_vm.pdxsgl == 7 || _vm.pdxsgles == 7) &&
							  this.$globalcolor == 'yellow'
							? 'hrefTXt  yellowcss'
							: (_vm.pdxsgl == 7 || _vm.pdxsgles == 7) &&
							  this.$globalcolor == 'blue'
							? 'hrefTXt  bluecss'
							: 'hrefTXt',on:{"click":function($event){return _vm.gotoHref('/alldownload', 7)},"mouseenter":function($event){return _vm.handleMouseEnter(7)},"mouseleave":_vm.handleMouseLeave}},[_vm._v(" 下载中心 ")])])]),_c('div',{staticStyle:{"height":"68px"}})]),_c('div',{staticClass:"m-center"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"logoBox"},[_c('img',{attrs:{"src":_vm.$baseImageUrl + _vm.foot.logo,"alt":""}}),_c('div',{staticStyle:{"margin-left":"10px","font-size":"26px","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.foot.webName)+" ")])]),_c('div',{staticClass:"selectBox"},[(!_vm.topShow)?_c('img',{staticClass:"seImg",attrs:{"src":require("@/assets/images/select.png"),"alt":""},on:{"click":_vm.openTop}}):_c('img',{staticClass:"clImg",attrs:{"src":require("@/assets/images/close_icon.png"),"alt":""},on:{"click":_vm.closeTop}})])]),_c('div',{staticStyle:{"height":"64px"}}),(_vm.topShow)?_c('div',{staticClass:"topCon"},[_c('div',{class:_vm.pdxsgl == 1 || _vm.pdxsgles == 1
						? 'hrefTXt textcol'
						: 'hrefTXt',on:{"click":function($event){return _vm.gotoHref('/', 1)},"mouseenter":function($event){return _vm.handleMouseEnter(1)},"mouseleave":_vm.handleMouseLeave}},[_vm._v(" 首页 ")]),_c('div',{class:_vm.pdxsgl == 5 || _vm.pdxsgles == 5
						? 'hrefTXt textcol'
						: 'hrefTXt',on:{"click":_vm.toLogin,"mouseenter":function($event){return _vm.handleMouseEnter(5)},"mouseleave":_vm.handleMouseLeave}},[_vm._v(" 用户登录 ")]),_c('div',{class:_vm.pdxsgl == 7 || _vm.pdxsgles == 7
						? 'hrefTXt textcol'
						: 'hrefTXt',on:{"click":function($event){return _vm.gotoHref('/alldownload', 7)},"mouseenter":function($event){return _vm.handleMouseEnter(7)},"mouseleave":_vm.handleMouseLeave}},[_vm._v(" 下载中心 ")]),_c('div',{staticClass:"serve"},_vm._l((_vm.csListData),function(item,index){return _c('div',{key:index},[(item.type == 3)?_c('div',{staticClass:"severCon severCon_phone"},[_c('div',{staticClass:"phoneTip"},[_c('div',{staticClass:"wcImg"},[_vm._v(" "+_vm._s(item.info)+" ")]),_c('i',{staticClass:"el-icon-caret-bottom arrow"})]),_c('img',{staticClass:"severImg",attrs:{"src":require("@/assets/images/phone.png"),"alt":""}}),_c('div',[_vm._v("客服热线")])]):_vm._e(),(item.type == 2)?_c('a',{staticClass:"severCon",attrs:{"title":"点击这里给我发消息","href":'http://wpa.qq.com/msgrd?v=3&uin=' +
							item.info +
							'&site=qq&menu=yes',"target":"_blank"}},[_c('img',{staticClass:"severImg",attrs:{"src":require("@/assets/images/qq.png"),"alt":""}}),_c('div',[_vm._v("在线客服")])]):_vm._e(),(item.type == 1)?_c('div',{staticClass:"severCon severCon_wc",on:{"click":_vm.opwcShow}},[_c('div',{staticClass:"wcTip"},[_c('div',{staticClass:"wcImg"},[_c('img',{attrs:{"src":_vm.$baseImageUrl + item.info,"alt":""}})]),_c('i',{staticClass:"el-icon-caret-bottom arrow"})]),_c('img',{staticClass:"severImg",attrs:{"src":require("@/assets/images/weChat.png"),"alt":""}}),_c('div',[_vm._v("微信客服")])]):_vm._e(),(item.type == 4)?_c('div',{staticClass:"severCon severCon_wc",on:{"click":_vm.opwcShow}},[_c('div',{staticClass:"wcTip"},[_c('div',{staticClass:"wcImg"},[_c('img',{attrs:{"src":_vm.$baseImageUrl + item.info,"alt":""}})]),_c('i',{staticClass:"el-icon-caret-bottom arrow"})]),_c('img',{staticClass:"severImg",staticStyle:{"border-radius":"50%"},attrs:{"src":require("@/assets/images/weChat_r2.png"),"alt":""}}),_c('div',[_vm._v("公众号客服")])]):_vm._e()])}),0)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }